import React, { useEffect } from 'react';

import { User, UserSource } from 'api/user/types';
import { intercomConfig } from 'config';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any;
  }
}
window.Intercom = window.Intercom || {};

export interface IntercomProps {
  disabled?: boolean;
  user?: User;
  verticalPadding?: number;
}
const APP_ID = intercomConfig.appId;

export function bootIntercom() {
  if (typeof window.Intercom === 'function') {
    window.Intercom('boot', {
      app_id: APP_ID,
      custom_launcher_selector: '.launch-intercom',
    });
  }
}

export function openHome() {
  if (typeof window.Intercom === 'function') {
    window.Intercom('showSpace', 'home');
  }
}

export function openHelpCenter() {
  if (typeof window.Intercom === 'function') {
    window.Intercom('showSpace', 'help');
  }
}

export function openMessages() {
  if (typeof window.Intercom === 'function') {
    window.Intercom('showSpace', 'messages');
  }
}

const IntercomBubble: React.FC<IntercomProps> = (props: IntercomProps) => {
  const { disabled = false, user, verticalPadding } = props;

  useEffect(() => {
    if (typeof window.Intercom === 'function') {
      if (disabled && user?.source === UserSource.epc) {
        window.Intercom('shutdown');
        return;
      }
      window.Intercom('update', {
        app_id: APP_ID,
        ...(user && {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          created_at: user.createdAt,
        }),
        hide_default_launcher: disabled,
        ...(verticalPadding && { vertical_padding: verticalPadding }),
      });
    }
  }, [user, disabled, verticalPadding]);

  return null;
};

export default IntercomBubble;
