import { Preferences } from '@capacitor/preferences';
import { NativeBiometric } from 'capacitor-native-biometric';
import Cookies from 'js-cookie';
import decodeJWT from 'jwt-decode';

import { PlatformConfig } from 'config';
import { clearSharedContentToken } from 'utils/sharedContent';

export const AUTH_TOKEN_KEY = 'authToken';
export const AUTH_TOKEN_EXPIRES_AT_KEY = 'authExpiresAt';
export const AUTH_REFRESH_TOKEN_KEY = 'authRefreshToken';

const isExpired = (expirationTimeSeconds: number) => expirationTimeSeconds * 1000 < new Date().getTime();

export const clearToken = (): void => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(AUTH_TOKEN_EXPIRES_AT_KEY);
  localStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);
};

export const storeAuthTokens = (token: string, refreshToken?: string): void => {
  const { exp } = decodeJWT<{ exp: number }>(token);
  if (isExpired(exp)) {
    throw new Error('Token is expired');
  }
  localStorage.setItem(AUTH_TOKEN_KEY, token);
  localStorage.setItem(AUTH_TOKEN_EXPIRES_AT_KEY, exp.toString());
  if (refreshToken) {
    localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, refreshToken);
  }
};

const cookieName = 'PalmettoHomeToken';
const cookieDomain = '.palmetto.com';
export const checkCookieForToken = (): void => {
  const token = Cookies.get(cookieName);
  if (token) {
    Cookies.remove(cookieName, { domain: cookieDomain });
    storeAuthTokens(token);
  }
};
export const impersonatedUserCookieName = 'ImpersonatedUserId';

export function getImpersonatedUserId(): string | undefined {
  return Cookies.get(impersonatedUserCookieName);
}
export function isImpersonatedUser(): boolean {
  const impersonatedUserId = getImpersonatedUserId();
  return Boolean(impersonatedUserId);
}

export function removeImpersonationCookie(): void {
  Cookies.remove(impersonatedUserCookieName, { domain: cookieDomain });
}
export function stopImpersonating(): void {
  removeImpersonationCookie();
  window.close();
}

export const getToken = (): string | undefined => {
  checkCookieForToken();
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  const exp: number = parseInt(localStorage.getItem(AUTH_TOKEN_EXPIRES_AT_KEY) || '0', 10);
  if (!token || !exp) {
    return undefined;
  }
  if (isExpired(exp)) {
    clearToken();
    return undefined;
  }
  clearSharedContentToken();
  return token;
};

export const getRefreshToken = (): string | undefined => {
  const refreshToken = localStorage.getItem(AUTH_REFRESH_TOKEN_KEY);
  const exp: number = parseInt(localStorage.getItem(AUTH_TOKEN_EXPIRES_AT_KEY) || '0', 10);
  if (!refreshToken || !exp) {
    return undefined;
  }
  if (isExpired(exp)) {
    clearToken();
    return undefined;
  }
  return refreshToken;
};

export async function areBiometricsAvailable(): Promise<boolean> {
  if (PlatformConfig.isNative) {
    try {
      return (await NativeBiometric.isAvailable()).isAvailable;
    } catch (e) {
      return false;
    }
  }
  return false;
}
export async function checkBiometrics(): Promise<boolean> {
  if (PlatformConfig.isNative) {
    try {
      if (await areBiometricsAvailable()) {
        const verified = await NativeBiometric.verifyIdentity({
          reason: 'For easy log in',
          title: 'Log in',
          description: 'Palmetto Biometric Log in',
        })
          .then(() => true)
          .catch(() => false);
        return verified;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
  return false;
}

const emailStorageKey = 'emailStorage';
const passwordStorageKey = 'passwordStorage';
const refreshTokenStorageKey = 'refreshTokenStorage';

export async function clearCredentialStorage(): Promise<void> {
  if (PlatformConfig.isNative) {
    try {
      await Preferences.clear();
    } catch (e) {
      console.warn(`Unable to clear native storage: ${e}`);
    }
  }
}

export async function hasStoredCredentials(): Promise<boolean> {
  if (PlatformConfig.isNative) {
    try {
      const email = await Preferences.get({ key: emailStorageKey });
      const password = await Preferences.get({ key: passwordStorageKey });
      const refreshToken = await Preferences.get({ key: refreshTokenStorageKey });

      const hasEmailAndPassword = !!(email.value && password.value);
      const hasRefreshToken = !!refreshToken.value;
      return (await areBiometricsAvailable()) && (hasEmailAndPassword || hasRefreshToken);
    } catch (e) {
      return false;
    }
  }
  return false;
}

export async function storeCredentials(email?: string, password?: string, refreshToken?: string): Promise<void> {
  if (PlatformConfig.isNative) {
    try {
      if (await areBiometricsAvailable()) {
        if (email && password) {
          await Preferences.set({ key: emailStorageKey, value: email });
          await Preferences.set({ key: passwordStorageKey, value: password });
        }
        if (refreshToken) {
          await Preferences.set({ key: refreshTokenStorageKey, value: refreshToken });
        }
      }
    } catch (e) {
      console.warn(`Unable to store credentials: ${e?.message}`);
      await clearCredentialStorage();
    }
  }
}

export async function getStoredCredentials(): Promise<
  { email: string; password: string } | { refreshToken: string } | undefined
> {
  if (PlatformConfig.isNative) {
    try {
      const refreshToken = (await Preferences.get({ key: refreshTokenStorageKey })).value;
      if (refreshToken) {
        return { refreshToken };
      }

      const email = (await Preferences.get({ key: emailStorageKey })).value;
      const password = (await Preferences.get({ key: passwordStorageKey })).value;
      if (email && password) {
        return { email, password };
      }
    } catch (e) {
      await clearCredentialStorage();
      console.warn(`Unable to retrieve credentials: ${e?.message}`);
    }
  }
  return undefined;
}
