import { useUser } from 'api/user/useUser';
import LinksCard, { CardLink } from 'components/LinksCard/LinksCard';
import { useTranslation } from 'components/_app/TranslationProvider/TranslationProvider';
import { SABAL_FINANCING_SOURCES } from 'config';
import { ApiFinanceAccountUnionResponse } from '../../../../api/payments/types';
import { FinancialAccountProduct, FinancialAccountSource } from '../../../../api/user/types';

export interface PaymentLinksProps {
  className?: string;
  financeAccount?: ApiFinanceAccountUnionResponse;
}

const cardTrackingId = 'payment-links';

const PaymentLinks = (props: PaymentLinksProps) => {
  const { className, financeAccount } = props;
  const { user } = useUser();
  const { t } = useTranslation();
  const isLightReachCustomer = user?.source && SABAL_FINANCING_SOURCES.has(user.source);
  const isUtilityRatePlan =
    financeAccount?.product === FinancialAccountProduct.utilityRatePlan &&
    financeAccount?.source === FinancialAccountSource.lightEnergy;

  let templateKey;

  if (isUtilityRatePlan) {
    return null;
  }
  if (isLightReachCustomer) {
    templateKey = 'lightReach';
  } else {
    templateKey = 'default';
  }
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  const templateLinks = t(`payments.paymentLinks.${templateKey}`, {
    returnObjects: true,
  }) as CardLink[];

  return (
    <LinksCard className={className} trackingId={cardTrackingId} links={templateLinks.filter((link) => link.href)} />
  );
};

export default PaymentLinks;
