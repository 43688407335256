import classnames from 'classnames';
import React from 'react';

import { PlatformConfig } from 'config';
import { useGetVersions } from 'hooks/useGetVersions';

import styles from './AppVersion.module.scss';

interface Props {
  className?: string;
}
const AppVersion: React.FC<Props> = (props: Props) => {
  const { className } = props;

  const { appVersion, apiVersion, binaryVersion } = useGetVersions();

  const displayVersions = [];
  if (appVersion) {
    if (['test', 'dev'].includes(PlatformConfig.environment) || PlatformConfig.isChromatic) {
      // Always render 0.0.0 in test environment, to keep snapshots consistent
      displayVersions.push('App v0.0.0');
    } else {
      displayVersions.push(`App v${appVersion}`);
    }
  }
  if (binaryVersion) {
    displayVersions.push(`Binary v${binaryVersion}`);
  }
  if (apiVersion) {
    displayVersions.push(`API ${apiVersion}`);
  }

  return displayVersions.length > 0 ? (
    <div className={classnames(styles.versions, className)}>
      {displayVersions.map((version, index) => (
        <span className={styles.version} key={version}>
          {version}
          {index + 1 < displayVersions.length && `,`}
        </span>
      ))}
    </div>
  ) : null;
};

export default AppVersion;
