import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { loginUser, LoginUser, LoginUserResponse, refreshUserToken } from 'api/login/login';
import { segmentReset } from 'components/_tracking/Segment/Segment';
import {
  checkBiometrics,
  clearCredentialStorage,
  clearToken,
  getStoredCredentials,
  getToken,
  hasStoredCredentials,
  removeImpersonationCookie,
} from 'utils/auth';
import { getFriendlyLoginError } from 'utils/error';

import authContext from './authContext';

export interface Props {
  children?: React.ReactNode;
}

const AuthProvider: React.FC<Props> = ({ children }: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [biometricsError, setBiometricsError] = useState<string>();
  const queryClient = useQueryClient();

  const {
    mutate: login,
    error: loginError,
    isLoading: isLoginLoading,
  } = useMutation<LoginUserResponse, AxiosError, LoginUser>(loginUser, {
    onSuccess: () => {
      setIsAuthenticated(true);
    },
  });

  const {
    mutate: refresh,
    error: refreshError,
    isLoading: isRefreshLoading,
  } = useMutation<LoginUserResponse, AxiosError, { refreshToken: string }>(refreshUserToken, {
    onSuccess: () => {
      setIsAuthenticated(true);
    },
  });

  const logout = () => {
    setIsAuthLoading(true);
    clearToken();
    clearCredentialStorage();
    removeImpersonationCookie();
    setIsAuthenticated(false);
    setIsAuthLoading(false);
    segmentReset();
  };

  useEffect(() => {
    const initAuth = async () => {
      const hasAuth = Boolean(getToken());
      if (hasAuth) {
        setIsAuthenticated(true);
        setIsAuthLoading(false);
        return;
      }
      if ((await hasStoredCredentials()) && (await checkBiometrics())) {
        const creds = await getStoredCredentials();
        try {
          if (creds) {
            if ('refreshToken' in creds) {
              refresh(creds);
            } else {
              login(creds);
            }
            return;
          }
        } catch (e) {
          await clearCredentialStorage();
          setBiometricsError('Sorry, but you are unable to log in with biometrics at this time');
        }
      }
      setIsAuthenticated(false);
      setIsAuthLoading(false);
    };
    initAuth();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsAuthLoading(isLoginLoading || isRefreshLoading);
  }, [isLoginLoading, isRefreshLoading]);

  // remove all cached queries when user logs in or out
  useEffect(() => {
    if (!isAuthenticated) {
      queryClient.removeQueries();
    }

    return () => queryClient.removeQueries();
  }, [isAuthenticated, queryClient]);

  const friendlyLoginError = getFriendlyLoginError(loginError || refreshError);

  const value = React.useMemo(
    () => ({
      login,
      logout,
      isAuthLoading,
      loginError: friendlyLoginError || biometricsError,
      isAuthenticated,
      setIsAuthenticated,
    }),
    [biometricsError, isAuthenticated, isAuthLoading, login, friendlyLoginError, setIsAuthenticated],
  );

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};

export default AuthProvider;
