// TODO: convert to a hook so we don't have to pass in user and flags
// https://palmetto.atlassian.net/browse/PA-3157

import { Property } from 'api/properties/types';
import { User, UserSource } from 'api/user/types';
import { LDFlags, SABAL_FINANCING_SOURCES } from 'config';
import { AssociatedEntityTypes } from 'core/associatedEntity';
import { doesStateHaveUtility } from 'utils/permittedUtilityProviders';
import {
  areAllProjectOrganizationsEligible,
  getInstalledSolarProject,
  isLegacySolarUser,
  isOrganizationEligible,
} from 'utils/referAFriend';
import { checkAccessFromSharedContentToken } from 'utils/sharedContent';

export type IFeatureConfig = (user?: User, flags?: LDFlags, property?: Property) => boolean;

export type FeatureConfigGroup = {
  [key: string]: ({ user, flags, property }: { user?: User; flags?: LDFlags; property?: Property }) => boolean;
};

const siteSurvey: IFeatureConfig = (user: User | undefined, flags: LDFlags | undefined) => {
  // show for any authenticated user, otherise check for site-survey token
  return Boolean(user) || checkAccessFromSharedContentToken('site-survey');
};

const service: IFeatureConfig = (user?: User) => Boolean(user && user.segmentation?.solarSystemCount);

const referAFriend: IFeatureConfig = (user?: User, flags?: LDFlags) => {
  if (!flags?.referAFriend) {
    return false;
  }
  const solarSystemCount = user?.segmentation?.solarSystemCount || 0;
  const solarProjects = user?.projects?.filter(
    (project) => project.type === 'solar' && project.stage !== 'cancelled',
  );

  if (isLegacySolarUser(solarSystemCount, solarProjects)) {
    return true;
  }

  // hvac projects or luminance users
  if (!solarProjects || solarProjects.length === 0) {
    return true;
  }

  const enabledOrganizationIds =
    (flags?.referAFriendOrganizations as { sabalOrganizationIds: string[] })?.sabalOrganizationIds || [];
  if (enabledOrganizationIds.length <= 0) {
    return false;
  }

  const installedSolarProject = getInstalledSolarProject(solarProjects);
  if (installedSolarProject) {
    return isOrganizationEligible(installedSolarProject, enabledOrganizationIds);
  }

  return areAllProjectOrganizationsEligible(solarProjects, enabledOrganizationIds);
};

const energyAdvisorPage: IFeatureConfig = (user, flags, property) => {
  return (
    (Boolean(flags?.energyAdvisorPage) &&
      Boolean(user?.associatedEntities?.find((e) => e.entityType === AssociatedEntityTypes.luminanceEstimate))) ||
    (Boolean(flags?.energyAdvisorV2) && Boolean(property?.hasEnergyAdvisorData))
  );
};

const financeAccountDetail: IFeatureConfig = (user?: User) => {
  return Boolean(user && user.financeAccounts?.length);
};

const gridUsageChart: IFeatureConfig = (user, flags, property) => {
  return Boolean(
    Boolean(user?.segmentation?.solarSystemCount) && property?.hasGridConnection && flags?.displayGridUsageChart,
  );
};

const energyProfile: IFeatureConfig = (_, flags?: LDFlags) => Boolean(flags?.energyProfile);

const utilityProvider: IFeatureConfig = (user?, flags?, property?) => {
  const isUtilityProviderFlagEnabled = Boolean(flags?.utilityProvider);
  const isEpcUser = user?.source ? SABAL_FINANCING_SOURCES.has(user.source) : false;
  const hasActiveSolarSystem = Boolean(user?.segmentation?.solarSystemCount);
  const hasSolarProject = user?.projects?.some((project) => project.type === 'solar');
  const isEpcUserWithSolar = isEpcUser && hasActiveSolarSystem;
  const nonEpcUserWithoutSolarProject = !isEpcUser && user?.projects?.length && !hasSolarProject;
  const nonEpcUserWithSolarSystem = !isEpcUser && hasActiveSolarSystem;
  const isValidUtilityUser = isEpcUserWithSolar || nonEpcUserWithoutSolarProject || nonEpcUserWithSolarSystem;

  const isInStateWithUtility = doesStateHaveUtility({ stateCode: user?.stateCode, flags });
  const hasGridConnection = Boolean(property?.hasGridConnection);
  const hasUtilityConnection = Boolean(user?.settings?.arcadiaPlugIntervals);
  return Boolean(
    isUtilityProviderFlagEnabled &&
      isValidUtilityUser &&
      isInStateWithUtility &&
      !hasGridConnection &&
      !hasUtilityConnection,
  );
};

const showProjectsExperience: IFeatureConfig = (_, flags?: LDFlags) => Boolean(flags?.showProjectsExperience);

const featuredItems: FeatureConfigGroup = {
  'heat-pump-upgrade': ({ property }: { property?: Property }) => {
    return Boolean(property?.address.state === 'MA');
  },
  'stand-alone-storage': ({ property, user }: { property?: Property; user?: User }) => {
    const allowedStates = new Set([
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'IL',
      'IN',
      'KY',
      'MA',
      'MD',
      'MO',
      'ME',
      'NC',
      'NJ',
      'NH',
      'OH',
      'OR',
      'PA',
      'RI',
      'SC',
      'TX',
      'VA',
      'VT',
    ]);
    const isFinanceUser = user?.source ? SABAL_FINANCING_SOURCES.has(user?.source) : false;
    const isPropertyInAllowedState = property ? Boolean(allowedStates.has(property?.address.state)) : false;
    const hasSolarSystem = Boolean(user?.segmentation?.solarSystemCount);

    return !isFinanceUser && isPropertyInAllowedState && hasSolarSystem;
  },
  'rate-plan-item': ({ property, user }: { property?: Property; user?: User }) => {
    const allowedStates = new Set(['TX']);
    const hasActiveSolarSystem = Boolean(user?.segmentation?.solarSystemCount);
    return property ? Boolean(allowedStates.has(property?.address.state)) && hasActiveSolarSystem : false;
  },
  windows: ({ property }: { property?: Property }) => {
    const allowedStates = new Set(['FL', 'CO', 'WA', 'UT', 'TX']);
    return property ? Boolean(allowedStates.has(property?.address.state)) : false;
  },
  solar: ({ user }: { property?: Property; user?: User }) => {
    const hasSolarProject = user?.projects?.some((project) => project.type === 'solar');
    const hasSolarSystem = Boolean(user?.segmentation?.solarSystemCount);
    return !hasSolarProject && !hasSolarSystem;
  },
};

const showEnergyAdvisorChartOnDashboard: IFeatureConfig = (user?: User) => {
  const isLuminanceUser = user?.source === UserSource.luminance;
  const hasSolarSystem = Boolean(user?.segmentation?.solarSystemCount);
  return isLuminanceUser && !hasSolarSystem;
};

export default {
  siteSurvey,
  service,
  referAFriend,
  energyAdvisorPage,
  financeAccountDetail,
  gridUsageChart,
  energyProfile,
  utilityProvider,
  showProjectsExperience,
  featuredItems,
  showEnergyAdvisorChartOnDashboard,
};
