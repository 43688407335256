import { ProjectStage } from 'api/project/types';
import { UserProjectResponse } from 'api/user/types';
import { associatedEntityLookup } from 'core/associatedEntity';

export const isLegacySolarUser = (
  solarSystemCount: number,
  solarProjects: UserProjectResponse[] | undefined,
): boolean => {
  if (solarSystemCount > 0 && (!solarProjects || solarProjects.length === 0)) {
    return true;
  }
  return false;
};

export const getInstalledSolarProject = (solarProjects: UserProjectResponse[]): UserProjectResponse | undefined => {
  const installedSolarProjectStages: ProjectStage[] = ['installationVerification', 'activationReview', 'completed'];
  return solarProjects.find((project) => installedSolarProjectStages.includes(project.stage));
};

export const isOrganizationEligible = (project: UserProjectResponse, enabledOrganizationIds: string[]): boolean => {
  const orgId = associatedEntityLookup(project).sabalOrganizationId();
  return orgId ? enabledOrganizationIds.includes(orgId) : false;
};

export const areAllProjectOrganizationsEligible = (
  solarProjects: UserProjectResponse[],
  enabledOrganizationIds: string[],
): boolean => {
  const solarProjectOrgIds = solarProjects.flatMap((project) => {
    const orgId = associatedEntityLookup(project).sabalOrganizationId();
    return orgId ? [orgId] : [];
  });

  return solarProjectOrgIds.length > 0 && solarProjectOrgIds.every((id) => enabledOrganizationIds.includes(id));
};
